import React from "react"
import styled from "styled-components";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "./../components/footer";

import IllustrationBlock from "./../components/illustration-block";

const TitleHolder = styled.div`
    background-color: #F9FAFE;
    width: 100%;
    text-align: center;
    margin: 0;

    h1 {
      margin: 0;
      padding: 50px 0 0 0;
      font-size: 2rem;
      font-weight: 500;
      font-family: 'Rufina', serif;
    }
`


const AboutPage = () => (
  <Layout>
    <SEO title="About Us"/>
    <TitleHolder><h1>About</h1></TitleHolder>
    <IllustrationBlock illustration={`dataReports`} background={`#F9FAFE`}>
        <p>
          Since its establishment in 2015, Synergistic Consultancy Services 
          has strived to provide a client-focused service with speed, integrity and precision. With our right blend of solution, approach and domain expertise, we enable customers to drive maximum business value.
        </p>
    </IllustrationBlock>
    <IllustrationBlock direction={`row-reverse`} illustration={`complexProblems`} background={`#F9FAFE`}>
        <p>
            Synergistic Consultancy Services is equipped to provide assistance in the most complex and challenging cases. In a world of increasingly complex applications, SCS’s remarkable underlying infrastructure allows us to run and deliver our services to multiple users in the ecosystem of businesses. Whatever your requirement, Synergistic Consultancy Services will strive to achieve it without settling for an easy solution.
        </p>
    </IllustrationBlock>
    <IllustrationBlock type={`image`} illustration={`dubai`} image background={`#F9FAFE`}>
    <p>Based in the United Arab Emirates, our international team provides world-class IT consultancy services and comprehensive staffing solutions at a global scale. Our wide reach gives us unrivaled advantage as we are able to be flexible, quick-to- act and prepared for challenges.</p>
    </IllustrationBlock>
    
    <Footer/>
  </Layout>
)

export default AboutPage
